import globalStore from '@/services/global.state';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import NoData from '@/components/basic/error-pages/no-data';
import theme from '@/style';
import Spin from '@/components/basic/spin';
import OrderCard from '@/components/business/order/order-card';
import PageNumber from '@/components/business/games/page';
import DrawResult from '../component/draw-result';
import TicketList from './ticket-list';
import {
  getOrders,
  LotteryOrder as LotteryOrderType,
} from '../mix-lottery-service';
import {copyText, getUUID, goTo, transfor} from '@/utils';
import dayjs from 'dayjs';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import {getShareAward} from '@/services/global.service';
import SharePanel from '@/components/business/share-panel/share-panel';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
import {SafeAny} from '@/types';

const pageSize = 3;

const LotteryOrder = ({
  lotteryID,
  refreshCode,
  isQuick,
}: {
  lotteryID?: number;
  refreshCode?: number;
  isQuick?: boolean;
}) => {
  const {shareSuccess, shareFail} = useShareToast();
  const [list, setList] = React.useState<LotteryOrderType[]>([]);
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const {i18n} = useTranslation();
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const panelRef = React.useRef<DrawerRef>(null);

  const getList = async (id: number, pageNo: number = 1) => {
    try {
      if (!firstLoad) {
        setLoading(true);
      }
      const res = await getOrders(id, isQuick, pageNo, pageSize);
      if (res) {
        setList(res.records || []);
        setTotal(res.totalCount);
        const award = await getShareAward(
          isQuick ? 'quickStateLottery' : 'stateLottery',
        );
        setShareAward(Math.floor(award));
      }
    } finally {
      setFirstLoad(false);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!globalStore.token) {
      goTo('Login');
      setFirstLoad(false);
      return;
    }
    if (lotteryID) {
      getList(lotteryID).then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryID]);

  React.useEffect(() => {
    if (refreshCode && lotteryID) {
      if (!globalStore.token) {
        return;
      }
      getList(lotteryID).then();
      setInitPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const onCopy = (id: string) => {
    copyText(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  const getStatus = (info: LotteryOrderType) => {
    if (isQuick) {
      return info.wonCode.every(v => v.indexOf('-') === -1) ? 4 : 2;
    } else {
      return info.wonCode.every(v => v.indexOf('-') === -1)
        ? info.ticketsLists.every(v => v.ticketStatus === 2)
          ? 4
          : 3
        : 2;
    }
  };

  return (
    <>
      <Spin
        loading={firstLoad || loading}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {minHeight: 300},
          theme.flex.flex1,
          total <= 3 && theme.padding.btml,
        ]}>
        {!firstLoad && !loading && list && list.length === 0 ? (
          <NoData />
        ) : null}
        {list.map((item, _i) => (
          <View key={_i}>
            <OrderCard
              hideDraw={false}
              id={item.orderGroup}
              hasWin={item.totalPrize > 0}
              status={getStatus(item)}
              betsTime={dayjs(item.createTime * 1000).format(
                'DD-MM-YYYY HH:mm',
              )}
              drawTime={dayjs(item.drawSec * 1000).format('DD-MM-YYYY hh:mm A')}
              payment={item.totalAmount}
              gameName={`${item.lotteryName} No.${item.roundNo}`}
              awardAmount={item.totalPrize}
              result={
                <DrawResult
                  wonCode={item.wonCode}
                  name={`${item.lotteryName} No.${item.roundNo}`}
                />
              }
              onCopy={() => onCopy(item.orderGroup)}
              onShare={() => {
                const user = localStorage.getItem('user') || '{}';
                const userId = JSON.parse(user).userId;
                setThreeOrderData({
                  userId,
                  data: transfor(
                    item,
                    isQuick ? 'quickStateLottery' : 'stateLottery',
                    {
                      from: 'order',
                    },
                  ),
                });
                panelRef.current?.open();
              }}
              hasAward={Boolean(shareAward)}
            />
            <TicketList
              ticketList={item.ticketsLists}
              isOpen={getStatus(item) !== 2}
            />
          </View>
        ))}
        {total > pageSize ? (
          <PageNumber
            total={total}
            page={initPage}
            pageSize={pageSize}
            onPageChange={p => {
              setInitPage(p);
              getList(lotteryID!, p).then();
            }}
          />
        ) : null}
      </Spin>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType={isQuick ? 'quickStateLottery' : 'stateLottery'}
          hasReward={Boolean(shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              {
                gameCode: isQuick ? 'quickStateLottery' : 'stateLottery',
                orderNo: getUUID(),
                threeOrderData,
              },
              (info: IShareInfo) => shareToChat(info),
              () => {
                shareSuccess(shareAward);
                setShareAward(0);
              },
              shareFail,
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default LotteryOrder;
